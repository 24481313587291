#header {
  color: #d3d3d3;
  background-color: #000;
}

#title {
  font-size: xx-large;
  font-weight: 400;
}

#version {
  font-size: medium;
}

#resultop {
  font-weight: bold;
}

/*# sourceMappingURL=index.3bd6b9ba.css.map */
