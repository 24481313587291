#header
{
    background-color: black;
    color: lightgray;
}

#title
{
    font-size: xx-large;
    font-weight: 400;
}

#version
{
    font-size: medium;
}

#resultop
{
    font-weight: bold;
}
